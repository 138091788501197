import Button from '../atoms/button/Button';
import {useNavigate} from 'react-router-dom';
import cx from 'classnames';
import styles from './Footer.module.css';
import { deleteRedirectUrl } from '../../utils/localStoreateUtil';

type FooterButtonProps = {
  icon: React.ReactNode;
  route: string;
  selected?: boolean;
};

const FooterButton = ({icon, route, selected}: FooterButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      unstyled
      className={cx(styles.itemButton, {[styles.selected]:  selected})}
      onClick={() => {
        deleteRedirectUrl();
        navigate(route);
      }}>
      {icon}
    </Button>
  );
};

export default FooterButton;