import cx from "classnames";
import { useLocation } from "react-router-dom";
import FooterButton from "./FooterButton";
import UserIcon from "../atoms/userIcon/UserIcon";
// import ChartIcon from '../atoms/chartIcon/ChartIcon';
import HomeIcon from "../atoms/homeIcon/HomeIcon";
import { StatLinkIcon, ClipBoardTextIcon, SearchIcon } from "../atoms/icons/Icons";
import styles from "./Footer.module.css";
import { ROUTES } from "../../constants";
import { useAuth } from "../../context/Auth";
import { useEffect, useState } from "react";
import { useTeamSelector } from "../../context/TeamSelecterContext";
import { ProfileTabsName } from "../../data/profile";

import { TabsName as SubmissionTabsName } from "../../data/submissions";

const noFooterPathList = [ROUTES.REGISTER, ROUTES.FORGOT_PASSWORD, ROUTES.LOGIN, ROUTES.SETUP_TEAM, ROUTES.FLASHSTAT, ROUTES.FLASHSTATTEAM, ROUTES.TEAM_SIGNUP];

const appDefinedRouts = Object.values(ROUTES);
const analysisRoutes = [ROUTES.SUBMISSIONS, ROUTES.ANALYSIS];
const dataSetRoutes = [ROUTES.DATA_SETS];
const searchRoutes = [ROUTES.SEARCH];

const Footer = () => {
  const { pathname } = useLocation();
  const { user, isReady, notifications } = useAuth();
  const [hidden, setHidden] = useState(false);
  const p = pathname.split('/')[1]

  const {
    // selectedTeam,
    // updateSelectedTeam,
    // toggleDrawer,
    // open,
    selectedPlayer,
  } = useTeamSelector();

  useEffect(() => {
    const isShowFooter = !!noFooterPathList.find(p => pathname.startsWith(p))
      setHidden(isShowFooter);
  }, [pathname]);

  //manager buttons: Home | Analysis | Enter Data | Roster | Settings
  //player buttons: Home | Analysis | Enter Data | Submissions | Settings
  return (
    <>
      {!hidden && isReady && user ? (
        <footer className={styles.footer}>
          <nav className={styles.container}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <FooterButton
                  icon={<HomeIcon />}
                  route={
                    !!selectedPlayer
                      ? `${ROUTES.PROFILE}/${ProfileTabsName.BIO}/${selectedPlayer.id}`
                      : ROUTES.HOME
                  }
                  selected={!!selectedPlayer ? ROUTES.PROFILE === '/'+p : !appDefinedRouts?.includes('/'+p)}
                />
              </li>
              <li className={styles.listItem}>
                <FooterButton
                  icon={<ClipBoardTextIcon />}
                  route={
                    !!selectedPlayer
                      ? `${ROUTES.SUBMISSIONS}/${SubmissionTabsName.OVERVIEW}/${selectedPlayer.id}`
                      : ROUTES.ANALYSIS
                  }
                  selected={analysisRoutes.includes('/'+p)}
                />
              </li>
              <li className={cx(styles.listItem, styles.centerButton)}>
                <FooterButton
                  icon={<StatLinkIcon />}
                  route={
                    !!selectedPlayer
                      ? `${ROUTES.DATA_SETS}/player/${selectedPlayer.id}`
                      : `${ROUTES.DATA_SETS}`
                  }
                  selected={dataSetRoutes.includes('/'+p)}
                />
              </li>
              <li className={cx(styles.listItem)}>
                <FooterButton
                  icon={<SearchIcon />}
                  route={`${ROUTES.SEARCH}`}
                  selected={searchRoutes.includes('/'+p)}
                />
              </li>
              <li className={cx(styles.listItem, styles.userListItem)}>
                <FooterButton
                  icon={
                    <i className={styles.profileImage}>
                      {!!notifications && <span className={styles.notifyIcon} />}
                      {!!user?.info?.photo ? (
                        <img
                          src={user?.info?.photo}
                          alt={user?.info.firstName}
                        />
                      ) : (
                        <UserIcon />
                      )}
                    </i>
                  }
                  route={ROUTES.SETTINGS}
                />
              </li>
            </ul>
          </nav>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
