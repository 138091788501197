import React from 'react';
import cx from 'classnames';

import styles from './HomeIcon.module.css';

export type Props = {
  className?: string;
};

const HomeIcon: React.FC<Props> = ({className}) => (
  <svg className={cx(styles.icon, className)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 28h-6c-1.105 0-2-0.895-2-2v0-6h-4v6c0 1.105-0.895 2-2 2v0h-6c-1.105 0-2-0.895-2-2v0-11.563c0-0.583 0.25-1.108 0.649-1.474l0.001-0.001 10-9.088c0.354-0.325 0.829-0.524 1.35-0.524s0.996 0.199 1.351 0.526l-0.001-0.001 10 9.087c0.4 0.367 0.65 0.892 0.65 1.475v11.563c0 1.105-0.895 2-2 2v0zM14 18h4c1.105 0 2 0.895 2 2v0 6h6v-11.563l-10-9.088-10 9.088v11.563h6v-6c0-1.105 0.895-2 2-2v0z"
      fill="white"
    />
  </svg>
);

export default HomeIcon;
