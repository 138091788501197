export const playerApiKey = (pId: string): string[] => {
  return ["account", `player:${pId}`];
};

export const rolesApiKey = () => {
  return ["roles"];
};

export const sportsApiKey = () => {
  return ["sports"];
};

export const accountApiKey = () => {
  return ["account"];
};

export const analyticsApiKey = (tId: string) => {
  return [`analytics:${tId}`];
};

export const teamApiKey = (tId: string) => {
  return ["account", `team:${tId}`];
};

export const submissionApiKey = (sId: string) => {
  return [`submission:${sId}`];
};
export const submissionNotesApiKey = (sId: string) => {
  return [`submission:${sId}`, 'notes'];
};
export const playerDatasetsApiKey = (pId: string, sId: string) => {
  return [`playerDataset:${pId}`, `submission:${sId}`];
};
export const goalDatasetsApiKey = (gId: string) => {
  return [`goalDataset:${gId}`];
};
export const submissionsApiKey = (tId: string, pId: string) => {
  return [`team:${tId}`, `player:${pId}`];
};

export const dataSetApiKey = (dsId: string) => {
  return [`dataSet:${dsId}`];
};

export const scorecardApiKey = (sId: string) => {
  return [`scorecard:${sId}`];
};
export const scorecardWithMetricApiKey = (sId?: string, mId?: string) => {
  if(!sId && !mId)
    return ['scorecardKey'];
  return ['scorecardKey', `scorecard:${sId || ''}`, `metric:${mId || ''}`];
};

export const teamRosterApiKey = (tId: string) => {
  return [`roster:${tId}`];
};
export const divisionApiKey = () => {
  return ["division"];
};
export const sportsListApiKey = () => {
  return ["sports"];
};
export const stateApiKey = () => {
  return ["states"];
};
export const emailLimitApiKey = () => {
  return ["emaillimit"];
};